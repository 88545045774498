<template>
    <div class="container">
        <!-- Outer Row -->
        <div class="row justify-content-center">
            <slot></slot>
        </div>

    </div>
</template>

<script>
    export default {
        name: "auth"
    }
</script>

<style scoped>

</style>